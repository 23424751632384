<template lang="pug">
include ../../../../configs/template
div.row
  div.col-12
    +select-validation('data.comments.additional_info.reason', 'rejectionReasons', 'rejectionReasonStatement', '"name_ukr"', '["required"]')
  div.col-12
    +textarea('data.comments.comment', 'comment')
  div(v-if="[STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID].includes(data.body.status_document)").col-12
    v-btn(color="error" @click="setReject" :loading="isLoading") {{$t('setReject')}}
  div(v-if="[STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.IN_PROCESSING].includes(data.body.status_document)").col-12
    v-btn(color="success" @click="setReject" :loading="isLoading") {{$t('save')}}
</template>
<script>
import { RejectFormSailorExperience } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  props: {
    statusDocument: {
      type: Number,
      default: STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID
    }
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      data: {
        ...this.$route.params,
        comments: {
          comment: null,
          additional_info: {
            reason: null,
            cancel_reason: true
          }
        },
        body: {
          status_document: this.statusDocument
        }
      },
      isLoading: false
    }
  },
  validations () { return { data: RejectFormSailorExperience() } },
  computed: {
    ...mapState({
      rejectionReasons: state => state.directory.rejectionReasons.filter(item => item.model_name !== 'lineinservicerecord')
    })
  },
  methods: {
    ...mapActions(['updateExperienceCertificateById']),
    async setReject () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true

      if (this.data.body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.REJECTED) {
        this.$swal({
          title: this.$t('warning'),
          text: this.$t('rejectingConfirmation'),
          icon: 'info',
          buttons: [this.$t('cancel'), this.$t('confirm')],
          dangerMode: true
        }).then(async (confirmation) => {
          if (confirmation) {
            const response = await this.updateExperienceCertificateById(this.data)
            if (SUCCESS_CODE.includes(response.code)) {
              this.$v.$reset()
            }
            this.isLoading = false
          }
        })
      } else {
        const response = await this.updateExperienceCertificateById(this.data)
        if (SUCCESS_CODE.includes(response.code)) {
          this.$v.$reset()
          this.$notification.success('infoExperienceDoc')
        }
        this.isLoading = false
      }
    }
  }
}
</script>
